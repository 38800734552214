<template>
  <div class="my-1">
    <span
      v-if="props.label != null"
      class="text-sm mb-1 text-black font-medium"
      v-text="props.label"
    />
    <div class="flex items-stretch">
      <slot name="icon"
        ><div
          v-if="prefix != null"
          class="p-2 bg-gray-100 text-xs font-medium flex items-center"
          v-text="props.prefix"
      /></slot>
      <input
        v-if="props.type !== 'textarea'"
        :step="props.step"
        :type="props.type"
        :placeholder="props.placeholder"
        :value="modelValue"
        class="px-1 py-2 w-full focus:outline-none focus:text-gray-600 border border-gray-300"
        :readonly="props.readonly"
        :class="{
          [inputClass ?? '']: inputClass,
          'border-2 border-red-500': isValid === 'error',
          'border-2 border-yellow-500': isValid === 'warn'
        }"
        :max="props.max"
        :min="props.min"
        @blur="onBlur"
        @input="onInputChange"
      />
      <textarea
        v-else
        :placeholder="props.placeholder"
        :value="modelValue"
        class="px-1 py-2 w-full focus:outline-none focus:text-gray-600 border border-gray-300"
        :readonly="props.readonly"
        :class="{
          'border-2 border-red-500': isValid === 'error',
          'border-2 border-yellow-500': isValid === 'warn'
        }"
        @blur="onBlur"
        @input="onInputChange"
      ></textarea>
      <button
        v-if="!props.readonly && unsetButton"
        type="button"
        class="bg-primary-600 hover:bg-primary-500 cursor-pointer text-white text-xs font-medium p-2 m-0"
        @click="clear"
        v-text="localization(`general.clear`)"
      />
      <button
        v-if="!props.readonly && removeButton"
        type="button"
        class="bg-primary-600 hover:bg-primary-500 cursor-pointer text-white text-xs font-medium p-2 m-0"
        @click="clear"
        v-text="localization(`general.remove`)"
      />
    </div>
    <span
      v-if="props.helpText"
      class="text-sm text-gray-500 mt-1"
      v-text="props.helpText"
    />
    <span
      v-if="isValid !== true && errorMessage"
      :class="{
        'text-red-500': isValid === 'error',
        'text-yellow-500': isValid === 'warn'
      }"
      class="text-red-500 font-medium my-2"
      v-text="props.errorMessage"
    ></span>
  </div>
</template>
<script lang="ts" setup>
import useDebounce from "@/features/_abstract/utils/debounce";
import {computed, inject, ref, watch} from 'vue'

const props = withDefaults(
  defineProps<{
    label?: string
    helpText?: string
    prefix?: string
    min?: number | string
    max?: number | string
    type?: string
    modelValue: any
    placeholder?: any
    readonly?: boolean
    unsetButton?: boolean
    removeButton?: boolean
    validation?: (value: unknown) => boolean | 'warn' | 'error'
    errorMessage?: string
    step?: number

    useTempAllocation?: boolean
    // Debounce works only with useTempAllocation
    useDebounce?: boolean
    debounceTime?: number

    inputClass?: string
    grow?: boolean
  }>(),
  {
    type: 'text',
    unsetButton: true,
    validation: (value: unknown) => true,
    step: 1,
    useTempAllocation: false,
    debounceTime: 500,
  }
)

const temp = ref(props.modelValue)
const modelValue = computed({
  get() {
    return props.useTempAllocation ? temp.value : props.modelValue
  },
  set(value) {
    if (props.useTempAllocation) temp.value = value
    else {
      emit('update:modelValue', value)
    }
  }
})

defineExpose({
  tempValue: temp
})

const onBlur = () => {
  if (props.useTempAllocation) {
    emit('update:modelValue', modelValue.value)
  }
}

const onDebounce = () => {
  if (props.useTempAllocation) {
    emit('update:modelValue', modelValue.value)
  }
}

const fnDebounce = useDebounce(onDebounce, props.debounceTime)
watch(modelValue, () => {
  if (props.useTempAllocation && props.useDebounce) {
    fnDebounce()
  }
})
const clear = () => {
  modelValue.value = null
  emit('update:modelValue', null)
}

const localization =
  inject<VueInjectLocalization>('localization') ?? ((...args: unknown[]) => {})
const emit = defineEmits(['update:modelValue'])
const onInputChange = (event: Event) => {
  if (!props.readonly) {
    const value = (event.target as unknown as HTMLInputElement).value
    modelValue.value = value.length === 0 ? null : value
  }
}

const isValid = computed(() => {
  if (typeof props.validation === 'function') {
    return props.validation(props.modelValue)
  }
  return true
})
</script>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({ name: 'DInput' })
</script>
